import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';

@Component({
  selector: 'app-call-logs',
  templateUrl: './call-logs.component.html',
  styleUrls: ['./call-logs.component.css']
})
export class CallLogsComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  heading = ["S.No.","User detail","Call again","Call time","Support user","Status","Duration", ""];
  calllog = {};
  limit=100;
  apiresponse = false;
  showMoreButton = true;
  disableShowMoreButton = false
  skipValue = 0;
  loaderDailog;
  statusList={
    "completed" : "The call was answered and has ended normally",
    "failed" : "The call could not be completed as dialled, most likely because the phone number was non-existent",
    "busy" : "The caller received a busy signal",
    "no-answer" : "The call ended without being answered",
    "PhoneVerification":"Customer hung-up before connecting to any agent" 
  }

  constructor(private apiservice : ApiService, private md: MatDialog, private snackBar: MatSnackBar, private localstorage: LocalStorageService, private router: Router, private sharedservice: SharedService) {

   }

  ngOnInit(): void {
    const params = {
      skip : this.skipValue,
      limit: this.limit
    }
    this.apiservice.exotelCallLog(params).subscribe(result =>{
      if(result && Array.isArray(result)){
      this.apiresponse = true;
        result.sort((a,b) => {
         return b.timestamp - a.timestamp;
        });
        this.calllog = result;
      
      if(result.length< this.limit){
          this.showMoreButton=false;
      }
      this.disableShowMoreButton = false;
      if(this.skipValue >= 100){
        this.loaderDailog.close();
      }
      }
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
        this.localstorage.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        // this.sharedservice.gettingServerError();
      }
    });
  }

  openFullProfile(node,event){
    if (node == 2) {
      event.preventDefault(); // Prevent default link navigation
      this.openSnackBarCall("User not exist", "top", "right");
    }else if (node) {
    const url = '/admin/profile?userid=' + node;
      window.open(url, '_blank');
      event.preventDefault(); // Prevent default link navigation
    } else {
      event.preventDefault(); // Prevent default link navigation
    this.openSnackBarCall("This phonenumber not listed in our system", "top", "right");
    }
  }

  getCallstatus(callData){
    const params = {
      callsid : callData.callsid
    }
    this.apiservice.getCallDetail(params).subscribe(result =>{
      if(result){
        if(result.status!='in-progress'){
         this.openSnackBarCall(result.status, "top", "right");
         callData.callsid=null;
         callData.disabled=false;
      }else{
        this.openSnackBarCall(result.status, "top", "right");
      }
      }
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
        this.localstorage.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        // this.sharedservice.gettingServerError();
      }
    });

  }

  clickToCall(rowdata){
    const params = 'toNumber=' + rowdata.userNumber;
    
    this.apiservice.clicktocall(params).subscribe(result =>{
      if(result){
      const statusType = result['type'];
      if (statusType == 1) {
        this.openSnackBarCall("query Error contact tech team", "top", "right");
      } else if (statusType == 2) {
       this.openSnackBarCall("user not exists", "top", "right");
      } else if (statusType == 3) {
       this.openSnackBarCall("outgoing support phonenumber not listed","top", "right");
      } else if (statusType == 4) {
      this.openSnackBarCall("not an indian number", "top", "right");
      } else if (statusType == 5) {
        this.openSnackBarCall("call in progress","top", "right");
        rowdata.callsid=rowdata.data.callsId;
        rowdata.disabled=true;
      } else if (statusType == 6) {
        this.openSnackBarCall("DND is active on user number", "top", "right");
      } else if (statusType == 7) {
        this.openSnackBarCall("Exotel api exception", "top", "right");
      } else if (statusType == 8) {
       this.openSnackBarCall("Service is only for producation","top", "right");
      }
        
      }
    },
      (err: HttpErrorResponse) => { // HttpErrorResponse for checking errors and we will show a msg to users
      if (err.status === 401) {
        this.localstorage.removeToken('user');
        this.router.navigate(['/login']);
      } else if (err.status >= 500) {
        this.openSnackBarCall("server error", "top", "right");

      }
    });

  }
  
  // show more
  showMoreData(){
    this.skipValue = this.skipValue + 100;
    this.disableShowMoreButton = true;
    this.loaderDailog = this.md.open(LoadingScreenComponent, { disableClose: true });
    
    this.ngOnInit();
}

openSnackBarCall(message: any, verticalPosition: 'top' | 'bottom', horizontalPosition: 'left' | 'center' | 'right') {
  this.snackBar.open(message, 'close', {
    duration: 1500,  // Duration in ms
    horizontalPosition: horizontalPosition,  // Can be 'left', 'center', 'right'
    verticalPosition: verticalPosition,  // Can be 'top', 'bottom'
    panelClass: ['custom-snackbar'],
  });
}

toMilSec(input) {
  const oneSecond = 1000;
  const oneMinute = oneSecond * 60;
  const oneHour = oneMinute * 60;

  const seconds = Math.floor((input % oneMinute) / oneSecond);
  const minutes = Math.floor((input % oneHour) / oneMinute);

  if (minutes === 0 && seconds === 0) {
    return '0 sec';
  }else {
    return minutes + ' min ' + seconds + ' sec'; 
  }
  
}

toMinSec(input){
  const minutes = Math.floor(input / 60);  
  const seconds = input % 60;  
  
  if (minutes === 0 && seconds === 0) {
    return '0 sec';
  }else {
    return minutes + ' min ' + seconds + ' sec'; 
  }
  
}

}
