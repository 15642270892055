<div class="row mx-0 bg-color">
    <div class="col-12 px-0">
        <!-- table data-->
        <div class="row mx-0 pt-4 pb-3 justify-content-center">
            <table class="table-size">
                <thead class="div-sticky" style="height: 50px !important;" *ngIf="apiresponse">
                    <tr class="oswald c-darkgray body-subtext text-center" style="background-color: #e1effb;">
                        <th *ngFor="let head of heading">{{ head }}</th>
                    </tr>
                </thead>
                
                <!-- whole data of table -->
                 <tbody class="opensans-regular c-darkgray small-text text-center" *ngIf="apiresponse">
                    <tr *ngFor="let item of calllog; let i = index" [ngStyle]="{'background-color': (i+1)%2==0 ? '#f2f2f2' : '#ffffff'}">

                        <td class="py-3" style="box-shadow: 0 0 3px 0 #d1d1d1;">
                            {{i+1}}
                        </td>

                        <td style="cursor: pointer;"><a href="#" (click)="openFullProfile(item.user[0].userid ? item.user[0].userid : 2, $event)">
                            {{ item.user[0].userid ? item.user[0].name : item.userNumber }}
                            <span *ngIf="item.user[0].userid"> ({{ item.user[0].userid }})</span>
                          </a>
                        </td>

                        <td class="clickable"><button *ngIf="item.disabled" (click)="getCallstatus(item)" mat-button class="sm-btn mat-elevation-z5" style="background-color: #4caf50;">
                            <span class="opensans-regular body-subtext c-white">get Status</span>
                        </button><button *ngIf="!item.disabled" (click)="clickToCall(item)" mat-button class="sm-btn mat-elevation-z5" style="background-color: #D50000;">
                            <span class="opensans-regular body-subtext c-white">Click to Call</span>
                        </button>
                        </td>

                        <td>
                            <span class="c-darkgray">{{item.calltime?item.calltime:item.callStartTime | date: 'long' }}</span>
                        </td>

                        <td>
                            <span class="c-darkgray">{{item.agent[0].userid?item.agent[0].name:item.from}}</span>
                        </td>

                        <td>
                            <span *ngIf="item.status=='PhoneVerification'" class="c-darkgray">missedCall Phoneverification</span>
                            <span *ngIf="item.status!='PhoneVerification' && item.direction == 'incoming'" class="c-darkgray">In coming</span>
                            <span *ngIf="item.status!='PhoneVerification' && item.direction != 'incoming'" class="c-darkgray">Out going</span>
                        </td>

                        <td>
                            <span *ngIf="item.duration != null" class="c-darkgray">{{ toMinSec(item.duration) }}</span>
                            <span *ngIf="item.duration == null && item.endtime" class="c-darkgray">{{ toMilSec((item.endtime - item.timestamp)) }}</span>
                        </td>

                        <td>
                            <p *ngIf="item.direction === 'incoming'">
                                <span class="c-darkgray" *ngIf="item.status === 'PhoneVerification'">
                                  {{ statusList['PhoneVerification'] }}
                                </span>
                                <span class="c-darkgray" *ngIf="item.status !== 'busy' && item.status !== 'no-answer' && item.duration === 0">
                                  The caller hung up before the call could be picked.
                                </span>
                                <span class="c-darkgray" *ngIf="item.status !== 'busy' && item.status !== 'no-answer' && item.duration !== 0">
                                  {{ item.agent[0].name }} ({{ item.agentNumber }}), picked up the call.
                                </span>
                              </p>

                              <p *ngIf="item.direction === 'outbound-api'">
                                <span class="c-darkgray" *ngIf="item.status !== 'busy' && item.status !== 'no-answer' && item.status !== 'failed' && item.duration === 0">
                                  The caller hung up before the call could be picked.
                                </span>
                                <span class="c-darkgray" *ngIf="item.status !== 'busy' && item.status !== 'no-answer' && item.status !== 'failed' && item.duration !== 0">
                                  {{ item.RecordingUrl }}
                                </span>
                                <span *ngIf="item.status === 'busy'">
                                  <span class="c-darkgray" *ngIf="item.userStatus === 'busy'">
                                    {{ item.agent[0].name }} ({{ item.agentNumber }}) received a busy signal from the user.
                                  </span>
                                  <span class="c-darkgray" *ngIf="item.agentStatus === 'busy'">
                                    {{ item.agent[0].name }} ({{ item.agentNumber }}) is busy.
                                  </span>
                                </span>
                                <span class="c-darkgray" *ngIf="item.status === 'no-answer'">
                                  <span class="c-darkgray" *ngIf="item.userStatus === 'no-answer'">
                                    The call ended without being answered by the user.
                                  </span>
                                  <span class="c-darkgray" *ngIf="item.agentStatus === 'no-answer'">
                                    The call ended without being answered by {{ item.agent[0].name }} ({{ item.agentNumber }}).
                                  </span>
                                </span>
                                <span class="c-darkgray" *ngIf="item.status === 'failed'">
                                  <span class="c-darkgray" *ngIf="item.userStatus === 'canceled'">
                                    The call was canceled by the user.
                                  </span>
                                  <span class="c-darkgray" *ngIf="item.agentStatus === 'canceled'">
                                    The call was canceled by {{ item.agent[0].name }} ({{ item.agentNumber }}).
                                  </span>
                                </span>
                              </p>
                        </td>


                    </tr>
                </tbody> 

                <!-- sklenton -->
                <div class="col-12 px-0">
                    <div class="row mx-0 justify-content-center ph-item" *ngIf="!apiresponse" >
                        
                        <div class="col-12 px-0 mat-elevation-z5" style="height:50px;"></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        <div class="col-12 px-0 mat-elevation-z5 skelton-class" ></div>
                        
                    </div>
                </div>
                
            </table> 
        </div>
        
        <!-- show more -->
         <div class="row mx-0 py-3" *ngIf="showMoreButton && apiresponse">
            <div class="col-12 px-0 text-center">
                <button class="showMoreButtonBgColor" mat-button (click)="showMoreData()"  [disabled]="disableShowMoreButton">SHOW MORE</button>
            </div>
        </div>
    </div>
</div>