import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { AdminComponent } from './admin/admin.component';
import { HeaderComponent } from './admin/header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AdminConsoleComponent } from './admin/admin-console/admin-console.component';
import { CreateUserComponent } from './admin/admin-console/create-user/create-user.component';
import { RemoveUserDialogComponent } from './admin/admin-console/remove-user-dialog/remove-user-dialog.component';
import { NpsRatingComponent } from './admin/nps-rating/nps-rating.component';
import { RatingReasonDialogComponent } from './admin/nps-rating/rating-reason-dialog/rating-reason-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfileComponent } from './admin/profile/profile.component';
import { PhotosComponent } from './admin/profile/photos/photos.component';
import { AboutComponent } from './admin/profile/about/about.component';
import { PreferencesComponent } from './admin/profile/preferences/preferences.component';
import { UserMatchesActionComponent } from './admin/profile/user-matches-action/user-matches-action.component';
import { SharedService } from './services/shared.service';
import { LocalStorageService } from './services/local-storage.service';
import { DataService } from './services/data.service';
import { adminGuardService, adminUrlAccessService, AuthService, sessionGuardService } from './services/auth.service';
import { SessionStorageService } from './services/session-storage.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ZiggeoModule } from 'angular-ziggeo';
import { DeclineReasonDialogComponent } from './admin/profile/decline-reason-dialog/decline-reason-dialog.component';
import { DeclineReasonsPhotosDialogComponent } from './admin/profile/photos/decline-reasons-photos-dialog/decline-reasons-photos-dialog.component';
import { ProfilesModerationComponent } from './admin/profiles-moderation/profiles-moderation.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActiveUserComponent } from './admin/active-user/active-user.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from './admin/profiles-moderation/confirmation-dialog/confirmation-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { SupportNotesComponent } from './admin/profile/support-notes/support-notes.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UpdateUserdetailsDialogComponent } from './admin/profile/update-userdetails-dialog/update-userdetails-dialog.component';
import { LoadingScreenComponent } from './admin/loading-screen/loading-screen.component';
import { FullPhotoviewDialogComponent } from './admin/profile/photos/full-photoview-dialog/full-photoview-dialog.component';
import { EditsComponent } from './admin/profile/edits/edits.component';
import { EmailTemplateDialogComponent } from './admin/profile/email-template-dialog/email-template-dialog.component';
import { ChartsModule } from 'ng2-charts';
import { ScreenerComponent } from './admin/profile/screener/screener.component';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { MatSliderModule } from '@angular/material/slider';
import { OopsErrorPageComponent } from './error-pages/oops-error-page/oops-error-page.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AccountManageComponent } from './admin/account-manage/account-manage.component';
import { CallLogsComponent } from './admin/call-logs/call-logs.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    ActiveUserComponent,
    HeaderComponent,
    LoadingScreenComponent,
    ProfilesModerationComponent,
    ConfirmationDialogComponent,
    ProfileComponent,
    DeclineReasonDialogComponent,
    PhotosComponent,
    DeclineReasonsPhotosDialogComponent,
    FullPhotoviewDialogComponent,
    AdminConsoleComponent,
    CreateUserComponent,
    RemoveUserDialogComponent,
    NpsRatingComponent,
    RatingReasonDialogComponent,
    PreferencesComponent,
    AboutComponent,
    UserMatchesActionComponent,
    ActiveUserComponent,
    SupportNotesComponent,
    UpdateUserdetailsDialogComponent,
    LoadingScreenComponent,
    EditsComponent,
    EmailTemplateDialogComponent,
    ScreenerComponent,
    OopsErrorPageComponent,
    DashboardComponent,
    AccountManageComponent,
    CallLogsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatButtonModule,
    MatMenuModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatTabsModule,
    ZiggeoModule,
    MatSlideToggleModule,
    MatRippleModule,
    MatSelectModule,
    MatNativeDateModule,
    ChartsModule,
    NgxSliderModule,
    MatSliderModule,
  ],
  providers: [
    SharedService,
    LocalStorageService,
    AuthService,
    DataService,
    adminGuardService,
    SessionStorageService,
    sessionGuardService,
    adminUrlAccessService,
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: ApiService },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
