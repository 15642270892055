import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { adminGuardService, adminUrlAccessService, sessionGuardService } from './services/auth.service';
import { ActiveUserComponent } from './admin/active-user/active-user.component';
import { ProfilesModerationComponent } from './admin/profiles-moderation/profiles-moderation.component';
import { AdminConsoleComponent } from './admin/admin-console/admin-console.component';
import { CreateUserComponent } from './admin/admin-console/create-user/create-user.component';
import { NpsRatingComponent } from './admin/nps-rating/nps-rating.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { OopsErrorPageComponent } from './error-pages/oops-error-page/oops-error-page.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AccountManageComponent } from './admin/account-manage/account-manage.component';
import { CallLogsComponent } from './admin/call-logs/call-logs.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [adminGuardService],
    children: [
      {
        path: 'account-details',
        component: AccountManageComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'activeuser',
        component: ActiveUserComponent
      },
      {
        path: 'moderation',
        component: ProfilesModerationComponent
      },
      {
        path: 'analytics/nps',
        component: NpsRatingComponent
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'call-logs',
        component: CallLogsComponent
      },
      {
        path: '**',
        redirectTo: 'dashboard',
      }
    ]

  },
  {
    path: 'admin-console',
    component: AdminComponent,
    canActivate: [adminUrlAccessService],
    children: [
      {
        path: '',
        component: AdminConsoleComponent
      },
      {
        path: 'add-user',
        component: CreateUserComponent
      },
      {
        path: '**',
        component: AdminConsoleComponent,
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [sessionGuardService]

  },
  {
    path: 'oops',
    component: OopsErrorPageComponent,
    data: {
      title: 'Server error'
    }
  },
  {
    path: '**',
    redirectTo: 'admin'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
